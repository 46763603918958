<app-layout>
  <div class="container mt-4">

    <div>
      <app-card>
        <div class="row mb-2">
          <div class="col">
            <h4 i18n="@@user-administration">Nutzerverwaltung</h4>
          </div>
          <div class="col-auto">
            <div
              class="btn btn-sm btn-primary"
              (click)="modalRef = modalService.show(addUserModal)"
              i18n="@@new-user"
            >
              Neuer Nutzer
            </div>
          </div>
        </div>
        <!-- Todo: Translated labels-->
        <lib-rag-datasupply-table-quicklook-aside
          [overrideTableClasses]="''"
          #table
          [service]="this.frontendService"
          [columns]="[
            { fieldName: 'name', label: 'Name' },
            { fieldName: 'email', label: 'E-Mail' }
          ]"
          [translatedLabels]="this.translatedLabels"
          i18n-noDataText="@@no-users"
          noDataText="Keine Nutzer vorhanden."
        >
          <ng-container *ngIf="table.selectedObject">
            <div class="px-3">
              <div class="form-group mb-2">
                <label
                  for="name"
                  i18n="@@name"
                >Name</label>
                <input
                  type="text"
                  class="form-control"
                  id="name"
                  name="name"
                  [(ngModel)]="table.selectedObject.name"
                  (ngModelChange)="editUser(table.selectedObject._id, { name: $event })"
                />
              </div>
              <div class="form-group mb-2">
                <label
                  for="email"
                  i18n="@@email"
                >E-Mail</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  name="email"
                  [(ngModel)]="table.selectedObject.email"
                  (ngModelChange)="
                editUser(table.selectedObject._id, { email: $event })
              "
                />
              </div>
              <div class="form-check">
                <input
                  #input
                  class="form-check-input"
                  type="checkbox"
                  id="useradmin"
                  [ngModel]="table.selectedObject?.permissions?.includes('admin')"
                  (ngModelChange)="editUser(table.selectedObject._id, {
                    isAdmin: !table.selectedObject?.permissions?.includes('admin')
                  })"
                />
                <label
                  class="form-check-label"
                  for="useradmin"
                  i18n="@@user-admin"
                >Nutzeradmin</label>&nbsp;<i
                  class="ri-question-line text-primary"
                  i18n-tooltip="@@user-admin-tooltip"
                  tooltip="Nutzeradmins können alle Räume & Nutzer editieren und die Globalen Einstellungen anpassen."
                ></i>
              </div>
              <div class="row">
                <div class="col col-auto">

                  <div
                    class="btn btn-danger"
                    (click)="modalRef = this.modalService.show(confirmDelete)"
                    i18n="@@delete-user"
                  >Nutzer löschen</div>

                </div>
                <div class="col col-auto">
                  <div
                    class="btn btn-primary"
                    (click)="modalRef = this.modalService.show(confirmInvite)"
                    i18n="@@invite-user"
                  >Nutzer einladen</div>
                </div>
              </div>
            </div>

          </ng-container>
        </lib-rag-datasupply-table-quicklook-aside>
      </app-card>
    </div>
  </div>

  <ng-template #addUserModal>
    <div class="modal-header">
      <h4
        class="modal-title pull-left"
        i18n="@@add-new-user"
      >Neuen Nutzer hinzufügen</h4>
      <button
        type="button"
        class="btn-close close pull-right"
        aria-label="Close"
        (click)="modalRef?.hide()"
      >
        <span
          aria-hidden="true"
          class="visually-hidden"
        >&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        <div class="form-group">
          <label
            class="form-check-label"
            for="name"
            i18n="@@name"
          >Name</label>
          <input
            #name
            id="name"
            class="form-control"
            type="text"
            [(ngModel)]="newUser.name"
          />
        </div>
        <div class="form-group">
          <label
            class="form-check-label"
            for="email"
            i18n="@@email"
          >E-Mail</label>
          <input
            #email
            id="email"
            class="form-control"
            type="email"
            [(ngModel)]="newUser.email"
          />
        </div>
        <div class="form-check">
          <label
            class="form-check-label"
            for="newuseradmin"
            i18n="@@useradmin"
          >Nutzeradmin</label>&nbsp;<i
            class="ri-question-line text-primary"
            i18n-tooltip="@@useradmin-tooltip"
            tooltip="Nutzeradmins können alle Räume & Nutzer editieren und die Globalen Einstellungen anpassen."
          ></i>
          <input
            #isAdmin
            id="newuseradmin"
            class="form-check-input"
            type="checkbox"
            [(ngModel)]="newUser.isAdmin"
          />
        </div>
        <hr>
        <div class="form-check">
          <label
            class="form-check-label"
            for="inviteOnCreate"
            i18n="@@send-invite"
          >Einladung per E-Mail verschicken</label>
          <input
            id="inviteOnCreate"
            [(ngModel)]="this.inviteOnCreate"
            class="form-check-input"
            type="checkbox"
          >
        </div>
        <div
          class="btn btn-primary mt-2"
          (click)="addUser(); modalRef.hide()"
          i18n="@@create"
        >
          Erstellen
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #confirmDelete>
    <div class="modal-body">
      <div>
        <ng-container i18n="@@confirm-delete-user">Sind Sie sicher, dass Sie den Nutzer unwiderruflich löschen
          wollen?</ng-container>
        <div class="text-center">
          <div class="btn-group">
            <div
              class="btn btn-primary mt-2"
              (click)="modalRef.hide()"
              i18n="@@no"
            >Nein</div>
            <div
              class="btn btn-light mt-2"
              (click)="deleteUser(table.selectedObject._id)"
              i18n="@@yes"
            >Ja</div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>



  <ng-template #confirmInvite>
    <div class="modal-body">
      <div>
        <ng-container i18n="@@invite-selected-user">Nutzer {{table.selectedObject.name}} jetzt einladen?</ng-container>
        <div class="text-center">
          <div class="btn-group">
            <div
              class="btn btn-primary mt-2"
              (click)="modalRef.hide()"
              i18n="@@no"
            >Nein</div>
            <div
              class="btn btn-light mt-2"
              (click)="this.inviteUser(table.selectedObject._id)"
              i18n="@@yes"
            >Ja</div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</app-layout>