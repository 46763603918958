<div
  [ngStyle]="{ 'border-bottom': true ? ' 1px solid #c0c0c0' : '' }"
  class="text-right text-muted mt-5"
>
  <small
    (click)="this.extended = !this.extended"
    class="cursor-pointer"
  >
    <i
      class="ri-arrow-down-s-fill"
      *ngIf="!extended"
    ></i>
    <i
      class="ri-arrow-up-s-fill"
      *ngIf="extended"
    ></i>
    <ng-container i18n="@@extended">ERWEITERT</ng-container>
  </small>
</div>

<div
  *ngIf="extended"
  class="pt-3"
>
  <ng-content></ng-content>
</div>