<i
  class="ri-folder-reduce-line cursor-pointer"
  type="button"
  i18n-tooltip="@@leave-sharing"
  tooltip="Freigabe verlassen"
  placement="top"
  container="body"
  (click)="openModal(template)"
>
</i>
<ng-template #template>
  <div class="modal-body text-center">
    <p i18n="@@confirm-leave-room">Diesen Raum verlassen?</p>
    <p i18n="@@confirm-leave-room-description">
      Der Raum wird <strong>nicht</strong> gelöscht, Sie verlieren nur die
      Möglichkeit diesen zu bearbeiten / anzusehen.
    </p>
    <button
      type="button"
      class="btn btn-default m-4"
      (click)="confirm()"
      i18n="@@yes"
    >Ja</button>
    <button
      type="button"
      class="btn btn-primary m-4"
      (click)="decline()"
      i18n="@@no"
    >Nein</button>
  </div>
</ng-template>