<app-layout>
  <div class="container mt-4">
    <app-card *ngIf="this.config">
      <div class="row mb-2">
        <div class="col">
          <b i18n="@@design">Design</b>
        </div>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item px-0">
          <div class="row">
            <div
              class="col-3"
              i18n="@@accent-color"
            >BBB Akzentfarbe</div>
            <div class="col ms-auto">
              <input
                class="form-control form-control-color p-0"
                type="color"
                [(ngModel)]="this.config.primaryColor"
              />
            </div>
          </div>
        </li>
        <li class="list-group-item px-0">
          <div class="row">
            <div
              class="col-3"
              i18n="@@sidekick-color"
            >Sidekick Akzentfarbe</div>
            <div class="col ms-auto">
              <input
                class="form-control form-control-color p-0"
                type="color"
                [(ngModel)]="this.config.sidekickColor"
              />
            </div>
          </div>
        </li>

        <li class="list-group-item px-0">
          <div class="row">
            <div
              class="col-3"
              i18n="@@welcome-message"
            >Willkommensnachricht</div>
            <div class="col ms-auto">
              <input
                class="form-control"
                type="text"
                [(ngModel)]="this.config.welcomeMessage"
              />
            </div>
          </div>
        </li>

        <li class="list-group-item px-0">
          <div class="row">
            <div
              class="col-3"
              i18n="@@logo-318-70"
            >Logo (318x70)</div>
            <div class="col ms-auto w-100">
              <img
                alt="logo"
                style="max-height: 2rem"
                [src]="this.logoUrl"
              />

              <div class="form-control mt-2">
                <input
                  #logoUpload
                  input
                  type="file"
                />
              </div>
            </div>
          </div>
        </li>
        <div class="row mb-2 mt-2">
          <div class="col">
            <b i18n="@@behavior">Verhalten</b>
          </div>
        </div>
        <li class="list-group-item px-0">
          <div class="row">
            <div
              class="col-3"
              i18n="@@enter-muted"
            >Beim Beitritt Stummschalten</div>
            <div class="col ms-auto">
              <input
                type="checkbox"
                [(ngModel)]="this.config.muteOnStart"
              />
            </div>
          </div>
        </li>

        <li class="list-group-item px-0">
          <div class="row">
            <div
              class="col-3"
              i18n="@@cam-only-for-moderators"
            >Webcams nur für Moderatoren</div>
            <div class="col ms-auto">
              <input
                type="checkbox"
                [(ngModel)]="this.config.webcamsOnlyForModerators"
              />
            </div>
          </div>
        </li>

        <div class="row mb-2 mt-2">
          <div class="col">
            <b i18n="@@more-system-settings">Weitere Systemeinstellungen</b>
          </div>
        </div>
        <li class="list-group-item px-0">
          <div class="row">
            <div
              class="col-3"
              i18n="@@tanlist-for-users"
            >TAN-Liste für Benutzer</div>
            <div class="col ms-auto">
              <input
                type="checkbox"
                [(ngModel)]="this.config.tanList"
              />
            </div>
          </div>
        </li>

        <p style="height: 5px"></p>
      </ul>
      <button
        class="btn btn-sm btn-primary me-1 mt-1"
        (click)="this.updateMandantConf()"
        i18n="@@save"
      >Speichern</button><button
        class="btn btn-sm text-primary me-1 mt-1"
        (click)="resetMandantConf()"
        i18n="@@reset-settings"
      >Einstellungen zurücksetzen</button>
    </app-card>
  </div>
</app-layout>