import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  BBBUser,
  BbbRoom,
  CheckJoinResult,
  ForbiddenReason,
  JoinInfo,
} from '@reflact/kick';
import { BbbService } from '../bbb.service';
import { AuthService } from '../shared/AuthService';

export type Roomitem = {
  name: string;
  id: string;
};

@Component({
  selector: 'app-join',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss'],
})
export class JoinComponent {

  public enterNameText: string = $localize`:@@enter-name:Bitte geben Sie ihren [b]Namen[/b] ein.`;
  public enterNamePasswordText: string = $localize`:@@enter-name-password:Bitte geben Sie ihren [b]Namen[/b] und das [b]Gästepasswort[/b] ein.`;
  public window = window;
  private joinParam: string;
  public room: JoinInfo & { short: string };
  private metaObject: object;
  public name = '';
  public loginform = false;
  public guestPassword: string;
  public invalidPassword: boolean;
  public maxParticipantsReached: boolean;
  public autojoin: boolean;
  public requestPasswordForGuests = false;
  public jwtuser: BBBUser;
  public errorMessage: ForbiddenReason | 'demomode' | 'missingguestid';
  public errorMap: Map<ForbiddenReason | 'demomode' | 'missingguestid', string> = new Map([
    ['meetingisfull', $localize`:@@meeting-is-full:Das Meeting ist bereits voll!`],
    ['maxconcurrentusersreached', $localize`:@@too-many-users:Es befinden sich zu viele Nutzer in Meetings!`],
    ['no Licenses', $localize`:@@require-license:Benötigte Lizenz nicht vorhanden!`],
    ['namedhostinothermeeting', $localize`:@@another-meeting-open:Sie haben bereits ein anderes Meeting geöffnet!`],
    ['nonamedhostformeeting', $localize`:@@wait-for-organizer:Bitte warten Sie, bis der Veranstalter eintrifft.`],
    ['demomode', $localize`:@@enter-in-demomode:Sie betreten den Raum im Demo Modus.`],
    ['missingguestid', $localize`:@@only-enter-with-individual-link:Dieser Raum kann nur mit individuellem Link betreten werden!`],
  ]);
  public successJoinResult: BbbRoom & { demo?: true };
  public guestId: string;

  constructor(
    private aRoute: ActivatedRoute,
    public service: BbbService,
    public router: Router,
    private authService: AuthService,
  ) {
    this.aRoute.params.subscribe((data) => {
      this.joinParam = data.id;
      this.guestId = data.guest_id;
    });

    this.aRoute.queryParams.subscribe(data => {
      this.metaObject = {}
      if (data.username) {
        this.name = data.username;
      }
      for (const key in data) {
        if (Object.hasOwn(data, key)) {
          if (key.startsWith('meta_')) {
            this.metaObject[key] = data[key];
          }
        }
      }
    });


    this.aRoute.data.subscribe((data) => {
      this.room = data.data;
      this.jwtuser = this.authService.getUserObject(this.room.short);
      // WENN KEIN GAST
      if (this.jwtuser) {
        // DAS IST MEIN RAUM
        if (
          this.room.creator === this.jwtuser._id ||
          this.room.editors?.includes(this.jwtuser._id)
        ) {
          this.autojoin = true;
          this.joinRoom(true);
          /* ICH WEISS DAS ICH AUF DER JOIN SEITE BIN UND KEIN FORMULAR MEHR SI!!!
          ALSO FRAGE ICH AL AN OB DEMO MODE IST!!!! */
        } else {
          // ICH BIN EINGELOGGT UND DAS IST NICHT MEIN RAUM
          this.name = this.jwtuser.name;
        }
      }
      if (this.room.access != 'password_individual' && this.guestId) {
        this.router.navigate(['../'], { relativeTo: this.aRoute });
      }
      // WENN GAST rein darf
      if (!this.jwtuser && (this.room.access === 'password' || this.room.access === 'password_individual')) {
        if (this.room.access === 'password_individual') {
          if (this.guestId == undefined) {
            this.errorMessage = "missingguestid"
          } else {
            this.name = this.room.guestName
          }
        }
        this.requestPasswordForGuests = true;
      }
      if (!this.jwtuser && this.room.access === 'internal') {
        this.loginform = true;
      }
    });
  }

  public async joinRoom(infoRequestOnly = false) {
    let res;
    this.invalidPassword = false;
    this.maxParticipantsReached = false;
    const short = this.aRoute.snapshot.paramMap.get("short")

    console.log("short for join is ", short)

    // Wenn es bereits einen erfolgreiches Join Result gibt, brauch ich kein neues anfragen
    if (!this.successJoinResult) {
      try {
        if (this.authService.getUserObject(this.room.short)) {
          res = await this.service.joinUrl(this.joinParam, short);
        } else if (this.room.access == 'password_individual') {
          res = await this.service.joinUrl(
            this.joinParam,
            short,
            this.guestId,
            this.guestPassword,
          );
        } else {
          res = await this.service.joinUrl(
            this.joinParam,
            short,
            this.name,
            this.guestPassword,
          );
        }

        // Speichert ein erfolgreichen Join in die Variable
        if (res.joinToken) this.successJoinResult = res;
      } catch (error) {
        if (error.status === 403) {
          this.invalidPassword = true;
        } else if (error.status === 420) {
          this.maxParticipantsReached = true;
        } else if (error.status === 412) {
          const result: CheckJoinResult = error.error;
          this.errorMessage = result.reason as ForbiddenReason;
        }
        return;
      }
    } else {
      // setzte res auf das vorhandene Join Result
      res = this.successJoinResult;
    }

    //  if demo mode
    if (res.demo) {
      this.errorMessage = 'demomode';
    }
    // wird nur ausgeführt wenn es kein demo request ist
    if (!infoRequestOnly && res.joinToken) {
      this.lastUsedRoomSaving(this.room);
      const finalUrl = new URL('https://' + res.servertouse);
      finalUrl.pathname = '/apps/sidekick-app';

      finalUrl.searchParams.append('joinToken', res.joinToken);
      for (const key in this.metaObject) {
        if (Object.hasOwn(this.metaObject, key)) {
          finalUrl.searchParams.append(key, this.metaObject[key]);
        }
      }

      // der serice holt den short aus dem eingeloggten nutzer, doof nur wenn der (gast-)nutzer nicht eingeloggt ist
      //finalUrl.hash = '#/' + this.service.getShort() + '_' + this.room._id + '?roomname=' + window.encodeURIComponent(this.room.name);
      finalUrl.hash = '#/' + short + '_' + this.room._id + '?roomname=' + window.encodeURIComponent(this.room.name);

      window.location.href = finalUrl.toString();
    }
  }

  public lastUsedRoomSaving(room: JoinInfo): void {
    const localRoom: Roomitem = ({ name: room.name, id: room._id })
    let rooms: Roomitem[] = JSON.parse(localStorage.getItem("myRooms"));

    if (rooms != null || rooms != undefined) {
      for (let i = 0; i < rooms.length; i++) {
        if (rooms[i].name.localeCompare(localRoom.name) == 0) {
          rooms.splice(i, 1);
          break;
        }
      }

      rooms.unshift(localRoom);

      while (rooms.length >= 5) {
        rooms.pop();
      }

      localStorage.setItem("lastJoinedRooms", JSON.stringify(rooms));
    } else { //Wenn noch nicht Vorhanden...
      rooms = [localRoom];
      localStorage.setItem('lastJoinedRooms', JSON.stringify(rooms));
    }
  }
}