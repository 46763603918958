<ng-container *ngIf="this.techcheck">
  <button
    class="btn btn-primary btn-lg btn-"
    (click)="this.newRoomName = 'Technik-Test-Raum'; this.create()"
    style="margin: 3%; min-width: 150px"
    i18n="@@start-technik-check"
  >
    Technik-Check starten
  </button>
</ng-container>

<ng-container *ngIf="!this.techcheck">
  <div
    class="input-group mb-3 m-auto"
    style="width: 300px;"
  >
    <input
      type="text"
      class="form-control me-0"
      [ngClass]="{ 'is-invalid': !this.roomNameIsValid }"
      i18n-placeholder="@@roomname"
      placeholder="Raumname"
      #newRoomInput
      [(ngModel)]="this.newRoomName"
      (keyup.enter)="this.create()"
      style="margin: 3%; min-width: 100px"
    />
    <button
      class="btn btn-primary ms-0"
      [disabled]="this.newRoomName.trim().length === 0"
      (click)="this.create()"
      style="margin: 3%;"
      i18n="@@create-room"
    >
      Raum anlegen
    </button>
  </div>
</ng-container>