import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FsPortalFile, PresentationFileMetadata } from '@reflact/kick';
import { BbbService } from 'src/app/bbb.service';

@Component({
  selector: 'app-file-list',
  templateUrl: './file-list.component.html',
  styles: [],
})
export class FileListComponent {
  @Input() public presentations: FsPortalFile<PresentationFileMetadata>[];
  @Output() public deleteFile: EventEmitter<string> = new EventEmitter<string>();

  public downloadableText: string = $localize`:@@downloadable:Herunterladbar`;
  public makeDownloadableText: string = $localize`:@@make-downloadable:Herunterladen erlauben`;
  public token = localStorage.getItem("bbbJWT")
  constructor(public service: BbbService) { }

  public async updateDownloadableStatus(
    presentation: FsPortalFile<PresentationFileMetadata>,
  ) {
    const result = await this.service.updatePresentation(
      presentation._id,
      !presentation.metadata.downloadable,
    );
    console.log("test")
    if (!result) return;
    presentation.metadata.downloadable = !presentation.metadata.downloadable;
  }
}
