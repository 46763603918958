import { Component, OnInit } from '@angular/core';
import { NgxForm } from '@reflact/ngx-forms';
import { BbbService } from './bbb.service';
import { AuthService } from './shared/AuthService';

export const translateTexts: NgxForm.TranslatedTexts = {
  create: $localize`:@@ngx-create:Erstellen`,
  delete: $localize`:@@delete:Löschen`,
  save: $localize`:@@save:Speichern`,
  revert: $localize`:@@reset:Verwerfen`,
  confirmNo: $localize`:@@no:Nein`,
  confirmYes: $localize`:@@yes:Ja`,
  confirmText: $localize`:@@confirm-text:Bist du dir sicher?`
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(private authService: AuthService, private bbbService: BbbService) { }

  public async ngOnInit(): Promise<void> {
    if (this.authService.getUserObject() !== null) {
      await this.bbbService.getMandantConfig();
    }
  }
}