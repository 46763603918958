<app-form-grop>
  <ng-container
    label
    i18n="@@room-url"
  >Raum-URL</ng-container>
  <ng-container input><input
      type="text"
      class="form-control"
      disabled
      value="{{ window.location.href }}join/{{ this.room._id }}/{{
        this.bbbService.getShort()
      }}"
    /></ng-container>
</app-form-grop>