<app-layout>
  <div class="container">
    <ng-container *ngIf="this.bbbService.isTechCheck()">
      <div class="alert">
        <div class="row">
          <div class="col-12">
            <b i18n="@@rag-technik-check">reflact kick! Technik-Check</b><br>
            <br>
            <ng-container i18n="@@technik-check-description">Dies ist der Technik-Check, um eine reibungslose Teilnahme
              an einem
              Online Meetings mit reflact-kick! zu garantieren.</ng-container><br>
            <div class="row mt-4">
              <div
                class="col"
                i18n="@@technik-check-start-description"
              >
                Um den Test zu starten klicken Sie oben auf "<b>Technik-Check starten</b>"<br>
                <br><br>
              </div>
            </div>
          </div>
          <div class="col-12">
            <b i18n="@@check-following-things">Bitte prüfen Sie folgende Dinge</b><br>
            <br>
            <div class="row">
              <div class="col-12">
                <i class="ri-number-1 number-icon"></i>
                <ng-container i18n="@@allow-cam-mic">Browserzugriff auf Kamera und Mikrofon ermöglichen</ng-container>
                <br>
                <small><b i18n="@@instuctions-title">Anleitung: </b>
                  <a
                    class="me-2"
                    target="_blank"
                    style="text-decoration: none"
                    href="https://url.reflact.com/kick-techcheck-manual-chrome"
                    i18n="@@google-chrome"
                  >
                    <i
                      class="ri-chrome-fill"
                      style="position: relative; top: 2px"
                    ></i>
                    Google Chrome</a>
                  <a
                    target="_blank"
                    style="text-decoration: none"
                    href="https://url.reflact.com/kick-techcheck-manual-edge"
                    i18n="@@microsoft-edge"
                  >
                    <i
                      class="ri-edge-fill"
                      style="position: relative; top: 2px"
                    ></i>
                    Microsoft Edge</a>
                </small>
              </div>
              <div class="col-12 mt-4">
                <i class="ri-number-2 number-icon"></i>
                <ng-container i18n="@@echo-test">Echotest durchführen (Mikrofon / Lautsprecher
                  aktivieren)</ng-container>
                <br>
                <img
                  alt="join with microphone"
                  class="mt-2"
                  src="assets/TechCheck_2.png"
                  width="50%"
                />
              </div>
              <div class="col-12 mt-4">
                <i class="ri-number-3 number-icon"></i>
                <ng-container i18n="@@activate-cam-optional">Kamera aktivieren <small>(optional)</small></ng-container>
                <br>
                <img
                  alt="settings"
                  class="mt-2"
                  src="assets/TechCheck_3.png"
                  width="50%"
                />
              </div>
            </div>
            <br><br>
            <b i18n="@@need-help">Sie benötigen Hilfe?</b><br>
            <i
              class="ri-mail-fill me-2"
              style="position: relative; top: 2px"
            ></i>
            <a
              target="_blank"
              href="mailto:kick.support@reflact.com"
              i18n="@@contact-support"
            >Support kontaktieren</a>
          </div>
        </div>
        <br>
      </div>
    </ng-container>
    <app-bbb-list *ngIf="!this.bbbService.isTechCheck()"></app-bbb-list>
    <p style="height: 10px"></p>
  </div>

</app-layout>