<app-form-grop>
  <span
    label
    i18n="@@share-with"
  >Teilen mit</span>
  <ng-select
    [items]="portalUsers | async"
    [multiple]="true"
    [clearOnBackspace]="false"
    bindLabel="name"
    bindValue="_id"
    [(ngModel)]="room.editors"
    (ngModelChange)="onEditorsChanged()"
  >
  </ng-select>
  <small
    class="form-text text-muted"
    i18n="@@share-with-desctiption"
  >Personen denen dieser Raum geteilt wird, werden diesen bearbeiten und
    verändern können.</small>
</app-form-grop>