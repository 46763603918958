<div
  class="modal-body"
  *ngIf="this.record._id"
>
  <h1
    class="p-3"
    i18n="@@recording-receiver"
  >Aufzeichnungsempfänger</h1>
  <div class="p-3">
    <app-guest-list-editor
      [list]="this.list"
      [linkOption]="'recording'"
      [record]="this.record"
      (addGuest)="this.addGuest($event)"
      (deleteGuest)="this.deleteGuest($event)"
      (updateGuest)="this.updateGuest($event)"
    ></app-guest-list-editor>
  </div>
</div>