<app-form-grop>
  <span
    label
    i18n="@@name"
  >Name</span>
  <input
    input
    [(ngModel)]="this.room.name"
    type="text"
    class="form-control"
  />
</app-form-grop>