<fieldset class="form-group">
  <div class="row">
    <legend
      class="col-form-label col-sm-3 p-0 font-weight-bold text-right pe-3"
      i18n="@@telco"
    >
      Telefonkonferenz
    </legend>
    <div class="col-sm-9">
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="telco"
          id="telco1"
          [value]="true"
          [checked]="this.room.telco"
          [(ngModel)]="this.room.telco"
        />
        <label
          class="form-check-label"
          for="telco1"
          i18n="@@active"
        >Aktiv</label>
      </div>
      <div class="form-check">
        <input
          class="form-check-input"
          type="radio"
          name="telco"
          id="telco2"
          [value]="false"
          [checked]="!this.room.telco"
          [(ngModel)]="this.room.telco"
        />
        <label
          class="form-check-label"
          for="telco2"
          i18n="@@inactive"
        >Inaktiv</label>
      </div>
      <small
        class="ps-2 text-muted form-text"
        i18n="@@telco-description"
      >Wenn aktiviert, wird eine Telefonkonferenz erzeugt.</small>
    </div>
  </div>
</fieldset>