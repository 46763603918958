<ng-template
  #accessTpl
  let-data="data"
>
  <i
    class="{{ this.bbbService.accessToIcon.get(data.access) }}"
    tooltip="{{ this.bbbService.accessToName.get(data.access) }}"
  ></i>
</ng-template>
<ng-template
  #adminActionsTpl
  let-data="data"
>

  <button
    class="btn btn-sm btn-primary me-2"
    (click)="addMeToRoom(data)"
    *ngIf="data.creator !== this.loginService.loggedInUser._id && !data.editors.includes(this.loginService.loggedInUser._id)"
    i18n="@@add-me"
  >
    <i class="ri-user-add-fill"></i> Mich hinzufügen
  </button>

  <div
    class="badge bg-secondary"
    *ngIf="data.creator === this.loginService.loggedInUser._id"
    i18n="@@you-are-owner"
  >Du bist Besitzer</div>
  <div
    class="badge bg-secondary"
    *ngIf="data.editors.includes(this.loginService.loggedInUser._id)"
    i18n="@@you-are-editor"
  >Du bist Bearbeiter</div>
</ng-template>
<ng-template
  #actionsTpl
  let-data="data"
>
  <a
    href="/join/{{ data._id }}/{{ this.bbbService.getShort() }}?roomname={{
      data.name
    }}"
    target="_blank"
    class="pe-3 no-underline"
    style="text-decoration: none"
  >
    <i
      class="ri-play-fill"
      type="button"
      i18n-tooltip="@@open-room"
      tooltip="Raum öffnen"
      placement="top"
      [ngClass]="{
        'text-success': bbbService.roomIdToIsRunning.get(data._id),
        'text-muted ': !bbbService.roomIdToIsRunning.get(data._id)
      }"
    ></i>
  </a>
  <span class="pe-3">
    <i
      class="ri-clipboard-line cursor-pointer"
      type="button"
      i18n-tooltip="@@copy-roomlink"
      tooltip="Raumlink kopieren"
      (click)="
        copyMessage(
          window.location.href +
            'join/' +
            data._id +
            '/' +
            this.bbbService.getShort() +
            '?roomname=' +
            window.encodeURIComponent(data.name)
        );
        confirmCopy($event)
      "
      placement="top"
    ></i>
  </span>
  <app-bbb-room-edit-button
    [room]="data"
    class="pe-3"
  ></app-bbb-room-edit-button>
  <span class="pe-3">
    <span [routerLink]="['attendances/' + data._id]">
      <i
        class="ri-group-fill cursor-pointer"
        placement="top"
        i18n-tooltip="@@attendences"
        tooltip="Anwesenheiten"
        type="button"
      ></i>
    </span>
  </span>
  <app-bbb-room-deletebutton
    *ngIf="isRoomOwner(data)"
    [room]="data"
    class="pe-3"
  ></app-bbb-room-deletebutton>
  <app-bbb-room-leavebutton
    *ngIf="!isRoomOwner(data)"
    [room]="data"
    class="pe-3"
  ></app-bbb-room-leavebutton>

</ng-template>
<ng-template
  #roomNameTpl
  let-data="data"
  let-showAnyways="showAnyways"
>
  <div class="row align-items-center">
    <div class="col-auto">{{ data.name }}</div>
    <div class="col"></div>
    <div
      class="col-auto text-end"
      *ngIf="this.userMap.size > 0"
    >
      <ng-container *ngIf="!isRoomOwner(data) || showAnyways">
        <small class="badge bg-light text-dark float-right mt-1">
          <ng-container i18n="@@owner-title">Besitzer:</ng-container>
          <small class="font-weight-normal">{{this.userMap.get(data.creator)?.name}}</small>
        </small>
      </ng-container>
      <br>
      <ng-container *ngIf="(isRoomOwner(data) || showAnyways) && data.editors && data.editors.length > 0 ">
        <small class="badge bg-light text-dark float-right mt-1">
          <ng-container i18n="@@shared-title">Geteilt:</ng-container>
          <small
            class="font-weight-normal"
            *ngFor="let editor of data.editors; let i = index"
          >
            <ng-container *ngIf="i < 2">
              {{this.userMap.get(editor)?.name}}<ng-container *ngIf="i < (data.editors.length-1)">,</ng-container>
            </ng-container>
          </small>
          <ng-container *ngIf="data.editors.length > 2"> +{{data.editors.length -2}}</ng-container>
        </small>
      </ng-container>
    </div>
  </div>
</ng-template>


<app-card>
  <tabset
    [justified]="true"
    #staticTabs
    *ngIf="this.isAdmin() else roomListTpl"
  >
    <tab
      i18n-heading="@@rooms"
      heading="Räume"
      class="pt-3"
    >
      <ng-container *ngTemplateOutlet="roomListTpl"></ng-container>
    </tab>
    <tab
      i18n-heading="@@roomsearch-admin"
      heading="Raumsuche (Admin)"
    >
      <div
        class="input-group mb-3 m-auto"
        style="width: 300px;"
      >
        <input
          type="text"
          class="form-control me-0"
          i18n-placeholder="@@room-name-link-owner"
          placeholder="Raumname, Raumlink, Benutzer"
          #newRoomInput
          [(ngModel)]="this.roomSearchString"
          (keyup.enter)="this.searchRooms()"
          style="margin: 3%; min-width: 200px"
        />
        <button
          class="btn btn-primary ms-0"
          [disabled]="this.roomSearchString.trim().length === 0"
          (click)="this.searchRooms()"
          style="margin: 3%;"
          i18n="@@search"
        >
          Suchen
        </button>
      </div>
      <table
        class="table"
        *ngIf="searched && this.searchedRoomList.length > 0"
      >
        <thead>
          <tr>
            <th i18n="@@access">Zugriff</th>
            <th i18n="@@roomname">Raumname</th>
            <th i18n="@@actions">Aktionen</th>
          </tr>
        </thead>
        <tbody>
          <tr
            *ngFor="let room of this.searchedRoomList"
            class="align-middle"
          >
            <td>
              <ng-container *ngTemplateOutlet="accessTpl; context: {data: room}"></ng-container>
            </td>
            <td>
              <ng-container *ngTemplateOutlet="roomNameTpl; context: {data: room, showAnyways: true}"></ng-container>
            </td>
            <td>
              <ng-container *ngTemplateOutlet="adminActionsTpl; context: {data: room}"></ng-container>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        class="alert alert-primary"
        *ngIf="searched && this.searchedRoomList.length === 0"
        i18n="@@no-rooms-found"
      >
        Keine Räume gefunden.
      </div>
    </tab>
  </tabset>

</app-card>
<ng-template #deleteConfirmModal>
  <div class="modal-body text-center">
    <p i18n="@@confirm-action">Möchten Sie diese aktion bestätigen?</p>
    <button
      type="button"
      class="btn btn-default"
      i18n="@@yes"
    >Ja</button>
    <button
      type="button"
      class="btn btn-primary"
      i18n="@@no"
    >Nein</button>
  </div>
</ng-template>

<ng-template #roomListTpl>
  <ng-container *ngIf="!this.bbbService.rooms.length">
    <div
      class="alert alert-info text-center w-75 m-auto"
      i18n="@@no-rooms-reminder"
    >
      <b>Sie haben noch kein kick!-Meeting angelegt.</b><br>
      Geben Sie Ihrem ersten kick!-Meeting einen Raumnamen und klicken
      anschließend auf <u>Raum Anlegen</u>
    </div>
  </ng-container>
  <app-bbb-room-createform [techcheck]="this.bbbService.isTechCheck()"></app-bbb-room-createform>
  <!-- TODO: TRANSLATEDLABELS-->
  <lib-rag-datasupply-table
    #table
    *ngIf="this.bbbService.rooms.length"
    [selectable]="false"
    [currentSort]="{
      sortField: 'name',
      sortDirection: 'asc'
    }"
    [overrideTableClasses]="''"
    [columns]="[
            {
              fieldName: 'access',
              label: 'Zugriff',
              template: accessTpl,
              disableSort: true
            },
            {
              fieldName: 'name',
              label: 'Raumname',
              template: roomNameTpl
            },
            {
              fieldName: 'actions',
              label: 'Aktionen',
              template: actionsTpl,
              disableSort: true
            }
          ]"
    [translatedLabels]="this.translatedLabels"
    [service]="this.dataSupplyService"
    (sort)="this.sortRooms($event)"
    i18n-noDataText="@@no-rooms"
    noDataText="Keine Räume vorhanden."
  >
  </lib-rag-datasupply-table>
</ng-template>