import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Attendance, BbbRoom } from '@reflact/kick';
import { RagDatasupplyFrontendService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import * as dayjs from 'dayjs';
import * as timezone from 'dayjs/plugin/timezone';
import * as utc from 'dayjs/plugin/utc';
import * as XLSX from 'xlsx';
import { BbbService } from '../bbb.service';
import { AuthService } from '../shared/AuthService';
import { AttendanceService } from '../shared/attendance.service';

@Component({
  selector: 'app-attendances-list',
  templateUrl: './attendances-list.component.html',
  styles: [],
  providers: [RagDatasupplyFrontendService]
})
export class AttendancesListComponent implements OnInit {
  public attendances: Attendance[];
  public dayjsHtml = dayjs;
  public roomId: string;
  public room: BbbRoom;
  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = [
    { fieldName: "userName", translated: $localize`:@@username:Nutzername` },
    { fieldName: "attendanceStartDate", translated: $localize`:@@from:von` },
    { fieldName: "attendanceEndDate", translated: $localize`:@@to:bis` },
    { fieldName: "attendanceDuration", translated: $localize`:@@duration:Dauer` },
  ]

  constructor(
    public activatedRoute: ActivatedRoute,
    public authService: AuthService,
    public bbbService: BbbService,
    public attendanceService: AttendanceService,
    public dataSupplyService: RagDatasupplyFrontendService<Attendance>,
  ) {
    this.activatedRoute.params.subscribe((route) => {
      this.roomId = route.roomId;
    });

    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault('Europe/Berlin');
  }

  public async ngOnInit(): Promise<void> {
    this.attendances = await this.attendanceService.getAttendancesForBBBRoom(this.roomId);
    this.room = await this.bbbService.getRoomInfo(this.roomId);
    this.dataSupplyService.fromArray(this.attendances);
  }

  public exportAttedances() {
    const data = this.attendances.map(a => {
      return {
        Name: a.userName,
        von: dayjs(a.attendanceStartDate).format("DD.MM.YYYY HH:mm:ss"),
        bis: dayjs(a.attendanceEndDate).format("DD.MM.YYYY HH:mm:ss"),
        Dauer: dayjs(a.attendanceDuration).utc().format("HH:mm:ss"),
      };
    })
    const ws = XLSX.utils.json_to_sheet(data);
    ws["!cols"] = [
      { wch: data.reduce((w, r) => Math.max(w, r.Name.length), 10) },
      { wch: data.reduce((w, r) => Math.max(w, r.von.length), 10) },
      { wch: data.reduce((w, r) => Math.max(w, r.bis.length), 10) },
      { wch: data.reduce((w, r) => Math.max(w, r.Dauer.length), 10) }
    ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws);
    XLSX.writeFileXLSX(workbook, this.room.name + '_attendances_' + dayjs().format('YYYYMMDD_HHmm') + '.xlsx')
  }
}
