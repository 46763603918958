<app-form-grop>
  <span
    label
    i18n="@@welcome"
  >Willkommen</span>
  <input
    input
    [(ngModel)]="this.room.welcome"
    type="text"
    class="form-control"
  />
  <small
    class="form-text text-muted"
    i18n="@@welcome-message-description"
  >Diese Nachricht wird allen Benutzern im Chat-Bereich nach Ihrem Beitritt
    angezeigt.</small>
</app-form-grop>

<app-record-editor [room]="this.room"></app-record-editor>
<app-telco-editor
  *ngIf="
    this.user.short.toLowerCase().startsWith('gak') ||
    this.user.short.toLowerCase() === 'develop'
  "
  [room]="this.room"
></app-telco-editor>