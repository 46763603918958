<div class="uploads-table">
  <div
    *ngFor="let item of uploads"
    class="row"
  >
    <label for="progress">{{ item.name }}</label>
    <progressbar
      id="progress"
      [max]="100"
      [value]="item.progress"
      type="info"
      [striped]="true"
      [animate]="true"
    ><i>{{ item.status }}</i></progressbar>
  </div>
</div>
<app-form-grop>
  <span
    label
    i18n="@@upload"
  >Hochladen</span>
  <div class="form-control">
    <input
      input
      type="file"
      [uploadx]="uploadxOptions"
      (state)="onUpload($event)"
    />
  </div>
</app-form-grop>
<app-form-grop *ngIf="presentations && presentations.length > 0">
  <span
    label
    i18n="@@existing-files"
  >Vorhandene Datein</span>
  <app-file-list
    (deleteFile)="this.deletePresentation($event)"
    [presentations]="presentations"
  ></app-file-list>
</app-form-grop>