import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BBBUser } from '@reflact/kick';
import { waitSeconds } from '@reflact/tsutil';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private allUsers: BBBUser[] = [];
  private isLoadingAllUsers = false;
  constructor(private http: HttpClient, private toastr: ToastrService) { }

  public async getAllPortalUser(): Promise<BBBUser[]> {

    if (this.isLoadingAllUsers) {
      await waitSeconds(1);
      return await this.getAllPortalUser();
    }
    this.isLoadingAllUsers = true;
    if (this.allUsers.length === 0) {
      const url = '/api/usersforshare/';
      this.allUsers = await firstValueFrom(this.http.get<BBBUser[]>(url));
    }
    this.isLoadingAllUsers = false;

    return this.allUsers;
  }

  public async getPartialPortalUsers(removeableIds: string[]): Promise<BBBUser[]> {
    return this.allUsers.filter(r => !removeableIds.includes(r._id));
  }

  public async addUser(newUser: Pick<BBBUser, 'email' | 'name' | 'permissions'>) {
    const url = '/api/user/add';
    const body = newUser;
    const result = await firstValueFrom(this.http.post<BBBUser>(url, body));
    this.toastr.info($localize`:@@user-created:Nutzer erstellt`)
    return result;
  }

  public async editUser(_id: string, updatedUser: Partial<BBBUser>) {

    const url = '/api/user/update';
    const body = updatedUser;
    body._id = _id;
    const result = await firstValueFrom(this.http.post<BBBUser>(url, body));
    this.toastr.info($localize`:@@user-saved:Nutzer gespeichert`)
    return result;
  }

  public async inviteUser(_id: string) {
    const url = '/api/user/invite/' + _id;
    let result;
    try {
      result = await firstValueFrom(this.http.get<BBBUser>(url));
    } catch (err) {
      this.toastr.error($localize`:@@invitiation-error:Fehler beim verschicken der Einladung`)
    }
    this.toastr.info($localize`:@@user-invited:Nutzer eingeladen`)
    return result;
  }


  public async deleteUser(_id: string) {
    const url = '/api/user/delete';
    const body = { _id };
    const result = await firstValueFrom(this.http.post<BBBUser>(url, body));
    this.allUsers = this.allUsers.filter(u => u._id != _id);
    this.toastr.info($localize`:@@user-deleted:Nutzer gelöscht`)
    return result;
  }
}