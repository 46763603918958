<div
  class="row m-2"
  *ngFor="let presentation of presentations"
>
  <div class="col">
    {{ presentation.filename }}
  </div>
  <div class="col-auto">
    <i
      [ngClass]="{
                    'ri-file-download-line ri-lg text-success':
                      presentation.metadata.downloadable,
                    'ri-file-forbid-line ri-lg text-danger': !presentation.metadata.downloadable
                  }"
      class="cursor-pointer me-4"
      [tooltip]="
                    presentation.metadata.downloadable
                      ? this.downloadableText
                      : this.makeDownloadableText
                  "
      (click)="updateDownloadableStatus(presentation)"
    ></i>
    <a
      class="cursor-pointer me-4"
      i18n-tooltip="@@download"
      tooltip="Download"
      [href]="'/api/presentation/' + presentation._id + '?token=' + this.token"
    ><i class="ri-download-2-fill ri-lg"></i></a>
    <i
      class="ri-delete-bin-2-fill ri-lg cursor-pointer"
      i18n-tooltip="@@delete"
      tooltip="Löschen"
      (click)="this.deleteFile.emit(presentation._id)"
    ></i>
  </div>
</div>