<div class="text-end mt-2">
  <div
    class="btn btn-primary"
    (click)="openModal(createModal, {
      localConnection: '',
      host: '',
      hasTelco: false,
      mandant: '',
      enabled: false
  })"
    i18n="@@new-server"
  >Neuer Server</div>
</div>
<!-- TODO: Translated labels-->
<lib-rag-datasupply-table
  [translatedLabels]="this.translatedLabelsTable"
  [service]="frontendService"
  [columns]="[
    {fieldName: 'enabled', label: 'Clusterverteilung', template: enabledTpl},
    {fieldName: 'hasTelco', label: 'Telco', template: telcoTpl},
    {fieldName: 'mandant', label: 'Cluster'},
    {fieldName: 'host', label: 'Host'},
    {fieldName: '', label: 'Internal', template: internIp}
  ]"
  (onSelect)="openModal(editModal, $event)"
></lib-rag-datasupply-table>


<ng-template #createModal>
  <div
    class="modal-body"
    *ngIf="this.modalRef?.content?.data"
  ><!-- TODO: Translated labels-->
    <rag-form
      [data]="this.modalRef.content.data"
      [translatedLabels]="this.translatedLabelsForm"
      [translateTexts]="this.translateTexts"
      [formFields]="[
        {fieldName: 'mandant', label: 'Mandant', type: 'select', required: true, options: {selectValues: this.cluster}},
        {fieldName: 'host', label: 'Host', type: 'text', required: true},
        {fieldName: 'localConnection', label: 'IP', type: 'text', required: true},
        {fieldName: 'enabled', label: 'Clusterverteilung', type: 'switch' },
        {fieldName: 'hasTelco', label: 'Telco', type: 'switch' },
      ]"
      [type]="'create'"
      (submit)="this.createServer($event)"
    ></rag-form>
  </div>
</ng-template>

<ng-template #editModal>
  <div
    class="modal-body"
    *ngIf="this.modalRef?.content?.data"
  ><!-- TODO: Translated labels-->
    <rag-form
      [data]="this.modalRef.content.data"
      [translatedLabels]="this.translatedLabelsForm"
      [translateTexts]="this.translateTexts"
      [formFields]="[
        {fieldName: 'mandant', label: 'Mandant', type: 'select', required: true, options: {selectValues: this.cluster}},
        {fieldName: 'host', label: 'Host', type: 'text', required: true},
        {fieldName: 'localConnection', label: 'IP', type: 'text', required: true},
        {fieldName: 'enabled', label: 'Clusterverteilung', type: 'switch' },
        {fieldName: 'hasTelco', label: 'Telco', type: 'switch' },
      ]"
      (submit)="this.updateServer($event)"
      (delete)="confirmConfirmDelete($event._id)"
    ></rag-form>
  </div>
</ng-template>

<ng-template
  #internIp
  let-data="data"
>
  <div class="ssh-link  badge bg-primary me-1 mb-1">
    <i class="ri-terminal-box-line pe-2"></i>
    <a
      (click)="openSSH(data.localConnection.substring(7).replace(':1337', ''))"
      style="text-decoration: underline;cursor:pointer;display: inline;font-family: monospace;"
    >ssh://root&#64;{{data.localConnection.substring(7).replace(":1337", "")}}</a>
  </div>
</ng-template>
<ng-template
  #telcoTpl
  let-data="data"
>
  <ng-container [ngSwitch]="data['hasTelco']">
    <ng-container *ngSwitchCase="true">
      <i class="ri-check-line text-success fw-bold"></i>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <i class="ri-close-line text-danger fw-bold"></i>
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{data['hasTelco']}}
    </ng-container>
  </ng-container>
</ng-template>
<ng-template
  #enabledTpl
  let-data="data"
>
  <ng-container [ngSwitch]="data['enabled']">
    <ng-container *ngSwitchCase="true">
      <i class="ri-check-line text-success fw-bold"></i>
    </ng-container>
    <ng-container *ngSwitchCase="false">
      <i class="ri-close-line text-danger fw-bold"></i>
    </ng-container>
    <ng-container *ngSwitchDefault>
      {{data['enabled']}}
    </ng-container>
  </ng-container>
</ng-template>