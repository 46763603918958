<app-form-grop>
  <ng-container
    label
    i18n="@@max-participants"
  >Teilnehmerbegrenzung</ng-container>
  <input
    input
    type="number"
    class="form-control"
    [(ngModel)]="this.room.maxParticipants"
    min="-1"
  />
  <small
    class="form-text text-muted"
    i18n="@@max-participants-description"
  >Legt eine Begrenzung der Teilnehmerzahl für diesen Raum fest.<br>
    -1 bedeutet, dass es keine Begrenzung gibt.</small>
</app-form-grop>