<i
  class="ri-delete-bin-2-fill cursor-pointer"
  type="button"
  i18n-tooltip="@@delete"
  tooltip="Löschen"
  placement="top"
  container="body"
  (click)="openModal(template)"
>
</i>
<ng-template #template>
  <div class="modal-body text-center">
    <p i18n="@@delete-room">Diesen Raum Löschen?</p>
    <button
      type="button"
      class="btn btn-default m-4"
      (click)="confirm()"
      i18n="@@yes"
    >Ja</button>
    <button
      type="button"
      class="btn btn-primary m-4"
      (click)="decline()"
      i18n="@@no"
    >Nein</button>
  </div>
</ng-template>