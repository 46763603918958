import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { BbbGuest, BbbRoom } from '@reflact/kick';
import { RagDatasupplyFrontendService, RagDatasupplyTypes } from '@reflact/rag-datasupply';
import Chance from 'chance';
import dayjs from 'dayjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BbbService, Recording } from 'src/app/bbb.service';
import { LoginService } from 'src/app/shared/login.service';
import { v4 as uuidv4 } from 'uuid';
import * as XLSX from 'xlsx';

export type GuestListExport = {
  name: string,
  password: string,
  link: string,
  recordingstatus?: number
}

@Component({
  selector: 'app-guest-list-editor',
  templateUrl: './guest-list-editor.component.html',
  styleUrls: ['./guest-list-editor.component.scss'],
  providers: [RagDatasupplyFrontendService, BsModalService]
})

export class GuestListEditorComponent implements OnInit {
  @Input({ required: false }) public roomId: string;
  @Input({ required: false }) public roomName: string;
  @Input({ required: true }) public list: BbbGuest[] = [];
  @Input({ required: true }) public linkOption: "room" | "recording";
  @Input({ required: false }) public record: Recording;
  @Output() public listChange: EventEmitter<BbbGuest[]> = new EventEmitter();
  @Output() public addGuest: EventEmitter<BbbGuest[]> = new EventEmitter();
  @Output() public deleteGuest: EventEmitter<BbbGuest[]> = new EventEmitter();
  @Output() public updateGuest: EventEmitter<BbbGuest> = new EventEmitter();
  @ViewChild('importInput') public importInput: ElementRef;
  public mode: "input" | "edit" = "edit";
  public searchString: string = "";
  public shownGuests: BbbGuest[] = [];
  public selectedGuests: string[] = [];
  public allSelected: boolean = false;
  public window = window;
  public modalRef?: BsModalRef;
  public hasRecordAValidRoom: boolean = false;
  private validRoomToRecording: BbbRoom | undefined;
  public translatedLabels: RagDatasupplyTypes.TranslatedLabels[] = [
    { fieldName: "name", translated: $localize`:@@name:Name` },
    { fieldName: "password", translated: $localize`:@@password:Passwort` },
  ]

  constructor(
    private modalService: BsModalService,
    public bbbService: BbbService,
    public frontendService: RagDatasupplyFrontendService<BbbGuest>,
    public loginService: LoginService
  ) { }

  public async ngOnInit() {
    this.shownGuests = this.list;
    if (this.linkOption == "recording") {
      try {
        this.validRoomToRecording = (await this.bbbService.searchRooms(this.record.metadata.roomName)).filter(r => r._id == this.record.metadata.roomId)[0];
        this.roomId = this.validRoomToRecording._id;
        this.roomName = this.validRoomToRecording.name;
        if (this.validRoomToRecording.guestList.length > 0) {
          this.hasRecordAValidRoom = true;
        }
      } catch (error) {
        this.roomId = this.record.metadata.roomId;
        this.roomName = this.record.metadata.roomName;
      }
    }
  }

  protected async updateGuestList(guestList: BbbGuest[]) {
    this.list = guestList;
    this.listChange.emit(guestList);
    this.filterChange();
  }

  protected async updateSingleGuest(guest: BbbGuest) {
    if (this.linkOption == "recording") {
      this.updateGuest.emit(guest);
    } else if (this.linkOption == "room") {
      this.updateGuestList(this.list.map(g => g.id == guest.id ? guest : g));
    }
  }

  public addToGuestList() {
    const guestId = uuidv4();
    if (this.linkOption == "recording") {
      this.addGuest.emit([{ id: guestId, name: "Max Mustermann", password: this.generateBSIPassword(), link: `/playback/play/${this.bbbService.getShort()}/${this.record._id}/${guestId}` }]);
    } else if (this.linkOption == "room") {
      this.list.push({ id: guestId, name: "Max Mustermann", password: this.generateBSIPassword(), link: '/join/' + this.roomId + '/' + this.bbbService.getShort() + '/' + guestId });
      this.updateGuestList(this.list);
    }
  }

  public filterChange() {
    this.shownGuests = this.list.filter(g => RegExp(this.searchString.toLocaleLowerCase()).exec(g.name.toLowerCase()));
  }

  public onExcelInput(event) {
    let data: BbbGuest[];
    const target: DataTransfer = <DataTransfer>(event.target);
    if (target.files.length > 1) {
      this.importInput.nativeElement.value = '';
      alert("Bitte nur eine Excel auswählen.");
    }

    const reader: FileReader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const wb: XLSX.WorkBook = XLSX.read(e.target.result, { type: 'binary', cellDates: true });
      data = XLSX.utils.sheet_to_json(wb.Sheets[wb.SheetNames[0]]);
    };
    reader.readAsBinaryString(target.files[0]);
    reader.onloadend = async () => {
      if (!data.every(g => g.name != undefined)) {
        this.importInput.nativeElement.value = '';
        alert("Nicht jeder Eintrag enthält einen Nutzernamen.")
        return;
      }
      data = data.map(g => {
        const guestId = uuidv4();
        let link = '';
        if (this.linkOption == "recording") {
          //this.list.push({ id: guestId, name: "Max Mustermann", password: this.generateBSIPassword(), link: `/playback/play/${this.bbbService.getShort()}/${this.recordId}/${guestId}` });
          link = `/playback/play/${this.bbbService.getShort()}/${this.record._id}/${guestId}`;
        } else if (this.linkOption == "room") {
          //this.list.push({ id: guestId, name: "Max Mustermann", password: this.generateBSIPassword(), link: '/join/' + this.room._id + '/' + this.bbbService.getShort() + '/' + guestId });
          link = '/join/' + this.roomId + '/' + this.bbbService.getShort() + '/' + guestId;
        }
        return {
          id: guestId,
          name: g.name.trim(),
          password: (g.password != undefined && g.password.trim() != '') ? g.password.trim() : this.generateBSIPassword(),
          link
        }
      });
      this.frontendService.fromArray(data);
    }
  }

  public async importGuestList() {
    if (this.linkOption == "recording") {
      this.addGuest.emit([...this.frontendService.rawData]);
      console.log(this.frontendService.rawData);
    } else if (this.linkOption == "room") {
      await this.updateGuestList([...this.list, ...this.frontendService.rawData])
    }
    this.frontendService.fromArray([]);
    this.importInput.nativeElement.value = '';
    this.mode = 'edit';
  }

  public exportGuestList() {
    const data = [...this.list].map((g: BbbGuest) => {
      const parsedGuest: GuestListExport = {
        name: g.name,
        password: g.password,
        link: window.location.href + g.link
      }
      if (this.linkOption == "recording") {
        if (g.hasWatchedRecording == undefined) {
          parsedGuest.recordingstatus = -1;
        } else if (g.hasWatchedRecording) {
          parsedGuest.recordingstatus = 1;
        } else if (!g.hasWatchedRecording) {
          parsedGuest.recordingstatus = 0;
        }
      }
      return parsedGuest;
    });
    const ws = XLSX.utils.json_to_sheet(data);
    ws["!cols"] = [
      { wch: data.reduce((w, r) => Math.max(w, r.name.length), 10) },
      { wch: data.reduce((w, r) => Math.max(w, r.password.length), 10) },
      { wch: data.reduce((w, r) => Math.max(w, r.link.length), 10) }
    ];
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws);
    if (this.linkOption === 'recording') {
      XLSX.writeFileXLSX(workbook, this.roomName + '_recordinglist_' + dayjs().format('YYYYMMDD_HHmm') + '.xlsx')
    } else if (this.linkOption === 'room') {
      XLSX.writeFileXLSX(workbook, this.roomName + '_guestlist_' + dayjs().format('YYYYMMDD_HHmm') + '.xlsx')
    }

  }

  public selectedIncludesAllShown() {
    return this.shownGuests.every(g => this.selectedGuests.includes(g.id))
  }

  public changeAllSelected(select: boolean) {
    if (select) {
      this.selectedGuests.push(...this.shownGuests.filter(g => !this.selectedGuests.includes(g.id)).map(g => g.id));
    } else {
      this.selectedGuests = []
    }
  }

  public changeSelection(id: string) {
    if (this.selectedGuests.includes(id)) {
      this.selectedGuests = this.selectedGuests.filter(s => s != id);
    } else {
      this.selectedGuests.push(id);
    }
    this.allSelected = this.selectedGuests.length == this.list.length;
  }

  public async deleteSelected() {
    if (this.linkOption == "recording") {
      const userForDeletion = this.list.filter(g => this.selectedGuests.includes(g.id))
      this.deleteGuest.emit(userForDeletion);
      const afterDeletion = this.list.filter(g => !this.selectedGuests.includes(g.id))
      await this.updateGuestList(afterDeletion);
    } else if (this.linkOption == "room") {
      const afterDeletion = this.list.filter(g => !this.selectedGuests.includes(g.id))
      await this.updateGuestList(afterDeletion);
    }
    this.allSelected = false;
    this.selectedGuests = [];
  }

  public confirmDelete(tpl: TemplateRef<BsModalRef>) {
    this.modalRef = this.modalService.show(tpl);
  }

  private generateBSIPassword(): string {
    const chance = new Chance();
    // Kleinbuchstaben
    let chanceString = chance.string({ length: 2, alpha: true, casing: "lower" })
    // Großbuchstaben
    chanceString += chance.string({ length: 2, alpha: true, casing: "upper" })
    // Zahlen
    chanceString += chance.string({ length: 2, numeric: true })
    // Sonderzeichen
    chanceString += chance.string({ length: 2, pool: "!@$%^()[]{}+-*" })
    // Durcheinander würfeln
    return chanceString.split('').sort(function () { return 0.5 - Math.random() }).join('')
  }

  public isAdmin(): boolean {
    return this.loginService.loggedInUser?.permissions?.includes('admin');
  }

  public async copyListFromRoomConfig() {
    const newGuests: BbbGuest[] = [];
    if (this.validRoomToRecording == undefined) {
      this.validRoomToRecording = await this.bbbService.getRoomInfo(this.record.metadata.roomId);
    }
    for (const guest of this.validRoomToRecording.guestList) {
      if (!this.list.some(g => g.id == guest.id)) {
        newGuests.push({ id: guest.id, name: guest.name, password: this.generateBSIPassword(), link: `/playback/play/${this.bbbService.getShort()}/${this.record._id}/${guest.id}` });
      }
    }
    this.addGuest.emit(newGuests);
  }
}
