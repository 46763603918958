<div class="overflow-hidden bg-light h-100">

  <ng-container *ngIf="this.passwordStatus !== 'valid'">
    <div
      class="row align-items-center"
      style="height: 100vh;"
    >
      <div class="col"></div>
      <div class="col-auto">
        <div class="input-group">
          <input
            #password
            class="form-control form-control-lg"
            i18n-placeholder="@@password"
            placeholder="Passwort"
            [(ngModel)]="passwordInput"
            type="password"
          >
          <button
            class="btn btn-primary btn-lg"
            [disabled]="passwordInput.trim().length === 0"
            (click)="checkPw()"
            i18n="@@go"
          >Los</button>
        </div>
        <small
          class="text-danger"
          *ngIf="this.passwordStatus === 'invalid'"
          i18n="@@invalid-password"
        >Passwort ungültig!</small>
      </div>
      <div class="col"></div>
    </div>
  </ng-container>

  <div [ngClass]="{'d-none':this.passwordStatus!=='valid'}">
    <video
      id="videoPlayer"
      #videoPlayer
      [controls]="this.input === 'global'"
      style="height: calc(100vh - 45px); width: 100%; max-width: 100vw; max-height:  calc(100vh - 45px); margin: auto;"
    >
      <source
        [src]="this.videoUrl"
        type="video/mp4"
      >
      <ng-container i18n="@@no-video-tag-support">Your browser does not support the video tag.</ng-container>
    </video>
    <div
      class="position-absolute row w-100 align-items-center overflow-hidden"
      style="z-index: 100; bottom: 0.5rem;"
      *ngIf="this.input === 'play'"
    >
      <div class="col-auto ps-4">
        <button
          class="btn btn-primary rounded-circle"
          (click)="videoPlayer.play(); this.videoPaused = true"
          *ngIf="!this.videoPaused"
        >
          <i class="ri-play-line"></i>
        </button>
        <button
          class="btn btn-primary rounded-circle"
          (click)="videoPlayer.pause(); this.videoPaused = false"
          *ngIf="this.videoPaused"
        >
          <i class="ri-pause-fill"></i>
        </button>
      </div>
      <div class="col">
        <progressbar
          class="w-100"
          style="cursor: not-allowed;"
          [value]="this.currentTime"
          [max]="this.durationTime"
          [animate]="true"
        ></progressbar>
      </div>
      <div class="col-auto">
        {{ this.currentTime | secondsToTime }} / {{ this.durationTime | secondsToTime }}
      </div>
    </div>
  </div>
</div>
<div
  class="position-fixed row align-items-center m-0 p-0"
  *ngIf="this.videoEnded && this.input === 'play'"
  style="z-index: 100; height: 100vh; width: 100vw; top: 0; background-color: #000b; left:0"
>
  <div class="col"></div>
  <div class="col-auto text-white fs-2">
    <h2
      class="text-center"
      i18n="@@status-saved"
    >
      Status gespeichert!
    </h2>
    <small i18n="@@page-can-be-closed">Diese Seite kann geschlossen werden!</small>

  </div>
  <div class="col"></div>

</div>

<ng-template #videoTpl>

</ng-template>