<app-form-grop>
  <span
    label
    i18n="@@access"
  >Zugriff</span>

  <select
    input
    name="access"
    class="form-control"
    [(ngModel)]="this.room.access"
  >
    <option
      *ngFor="let access of this.service.accessToName | keyvalue: originalOrder"
      value="{{ access.key }}"
    >
      {{ access.value }}
    </option>
  </select>
  <input
    type="text"
    class="form-control"
    disabled
    [(ngModel)]="this.room.password"
    *ngIf="this.room.access === 'password'"
  />


  <div
    class="text-muted"
    [ngSwitch]="this.room.access"
  >
    <small
      *ngSwitchCase="'public'"
      i18n="@@room-is-public"
    >
      <b>Öffentlich:</b> Jeder, der den Link kennt, kann beitreten.
    </small>
    <small
      *ngSwitchCase="'password'"
      i18n="@@room-has-password"
    ><b>Passwort:</b> Gäste müssen ein Passwort eingeben.</small>
    <small
      *ngSwitchCase="'password_individual'"
      i18n="@@room-has-individual-password"
    >
      <b>Passwort (individual):</b> Gäste müssen ihr individuelles Passwort eingeben(<a
        class="text-primary text-decoration-underline cursor-pointer"
        (click)="this.guestListClick.emit(true)"
      >Gästeliste</a>).
    </small>
    <small
      *ngSwitchCase="'waiting_room'"
      i18n="@@room-is-moderated"
    >
      <b>Einzeln Bestätigen:</b> Ein Moderator muss jeden Gast akzeptieren, bevor er beitreten kann.
    </small>
    <small
      *ngSwitchCase="'internal'"
      i18n="@@room-is-internal"
    ><b>Intern:</b> Nur Portal-Benutzer können beitreten.</small>
  </div>
</app-form-grop>